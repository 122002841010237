import React from "react"
import { PageProps, Link } from "gatsby"
import { Layout } from "../components"

const NotFoundPage: React.FC<PageProps> = () => (
  <Layout title="Stránka nenalezena">
    <article className="px-8 text-center pt-72 ">
      <h1 className="">Error 404</h1>
      <section className="pt-6">
        Stránka nenalezena, vraťte se domů a zkus to znovu
      </section>
      <Link to="/"><button className="mt-4 tracking-wider btn-lg">Domů</button></Link>
    </article>
  </Layout>
)

export default NotFoundPage
